import styled from 'styled-components'
import Text from 'components/Text'

export const Wrapper = styled.div(({theme}) => ({
  paddingBottom: 80,

  [theme.breakpoints.down('tablet')]: {
    paddingBottom: 60,
  },
}))

export const InnerWrapper = styled.div(({theme}) => ({
  maxWidth: 700,

  '& a': {
    color: 'blue',
  },
}))

export const Title = styled(Text)(({theme}) => ({
  textTransform: 'uppercase',
}))
