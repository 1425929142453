import styled from 'styled-components'
import Text from '../../components/Text'

export const Wrapper = styled.div(({theme}) => ({
  padding: '80px 0',
  background: theme.palette.primary.bg,

  [theme.breakpoints.down('tablet')]: {
    padding: '60px 0',
  },
}))

export const InnerWrapper = styled.div(({theme}) => ({
  maxWidth: 700,
}))

export const MarkdownWrapper = styled.div(({theme}) => ({
  '& p': {
    margin: 0,
  },

  '& a': {
    color: 'blue',
  },
}))

export const Title = styled(Text)(({theme}) => ({
  textTransform: 'uppercase',
}))

export const Subtitle = styled(Text)(({theme}) => ({
  fontSize: theme.fontSizes.md,
}))

export const ServicesItems = styled.div(({theme}) => ({
  marginBottom: 15,
  display: 'flex',
  flexDirection: 'column',
}))

export const ServicesItem = styled.div(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
}))
