import styled from 'styled-components'
import ContentContainer from 'components/ContentContainer'

export const Container = styled(ContentContainer)(({theme}) => ({
  position: 'absolute',
  top: 800,
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 3,
  pointerEvents: 'none',

  [theme.breakpoints.down('laptopS')]: {
    position: 'static',
    transform: 'none',
  },
}))

export const Inner = styled.div(({theme}) => ({
  marginBottom: 30,
  marginLeft: 'auto',
  padding: '11px 25px 28px 25px',
  maxWidth: 370,
  background: theme.palette.common.white,
  border: `1px solid ${theme.palette.common.black}`,
  pointerEvents: 'all',

  [theme.breakpoints.down('laptopS')]: {
    maxWidth: 375,
    marginLeft: -14,
    marginRight: -14,
    marginBottom: 0,
  },
}))

export const ContactInfoWrapper = styled.div(({theme}) => ({
  marginBottom: 15,

  '& > div > p': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flexStart',
  },

  '& p': {
    marginBottom: 0,
  },
}))
