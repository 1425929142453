import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import MarkdownView from 'react-showdown'
import {Inner, Container, ContactInfoWrapper} from './LawyerContacts.styles'
import Text from '../Text'
import CommonContactsText from '../CommonContactsText'
import VideoConferenceIcons from '../VideoConferenceIcons'

const LawyerContacts = ({name, position, street, contactInfo, speak, ...rest}) => {
  const {t} = useTranslation()
  return (
    <Container {...rest}>
      <Inner>
        <Text content={t('lawyer.contactsTitle')} mb={22} uppercase fontWeight={700} />
        <Text content={name} mb={0} fontWeight={700} />
        <Text content={position} mb={12} />
        <Text content={street} mb={12} />
        <ContactInfoWrapper>
          <MarkdownView markdown={contactInfo} options={{strikethrough: true, noHeaderId: true}}/>
        </ContactInfoWrapper>
        <Text content={speak} mb={22} />
        <CommonContactsText />
        <VideoConferenceIcons />
      </Inner>
    </Container>
  )
}

LawyerContacts.propTypes = {}
LawyerContacts.defaultProps = {}

export default LawyerContacts
