import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import MarkdownView from 'react-showdown'

import {
  Wrapper,
  Title,
  Subtitle,
  ServicesItems,
  ServicesItem,
  InnerWrapper,
  MarkdownWrapper,
} from './LawyerFeature.styles'
import ContentContainer from '../../components/ContentContainer'
import Text from '../../components/Text'

const LawyerFeature = ({title, subtitle, services, servicesText, ...rest}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()
  return (
    <Wrapper {...rest}>
      <ContentContainer>
        <InnerWrapper>
          <Title content={title} mb={10} size="titleXs" />
          <Subtitle content={subtitle} mb={30} />
          <ServicesItems>
            {services.map(item => (
              <ServicesItem key={item.name[language]}>• {item.name[language]}</ServicesItem>
            ))}
          </ServicesItems>
          <MarkdownWrapper>
            <MarkdownView markdown={servicesText} options={{strikethrough: true, noHeaderId: true}} />
          </MarkdownWrapper>
        </InnerWrapper>
      </ContentContainer>
    </Wrapper>
  )
}

LawyerFeature.propTypes = {}
LawyerFeature.defaultProps = {}

export default LawyerFeature
