import React from 'react'
// import PropTypes from 'prop-types'
import MarkdownView from 'react-showdown'
import {Wrapper, Title, InnerWrapper} from './AboutLawyer.styles'
import ContentContainer from '../../components/ContentContainer'

const AboutLawyer = ({content, ...rest}) => (
  <Wrapper {...rest}>
    <ContentContainer>
      <InnerWrapper>
        <MarkdownView markdown={content} options={{strikethrough: true, noHeaderId: true}} />
      </InnerWrapper>
    </ContentContainer>
  </Wrapper>
)

AboutLawyer.propTypes = {}
AboutLawyer.defaultProps = {}

export default AboutLawyer
