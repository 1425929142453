import React, {useEffect} from 'react'
import {createGlobalStyle} from 'styled-components'
import {graphql} from 'gatsby'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import BlogBlockSection from '../sections/BlogBlockSection'
import BlogNav from '../components/BlogNav'
import HeroWithSlider from '../components/HeroWithSlider'
import AboutLawyer from '../sections/AboutLawyer'
import LawyerFeature from '../sections/LawyerFeature'
import ReviewsSection from '../components/ReviewsSection'
import LawyerContacts from '../components/LawyerContacts'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Layout from "../components/Layout";

const GeneralContactsHide = createGlobalStyle`
  .GeneralContacts {
    display: none;
  }
`

const ArticleTemplate = ({
  pageContext: {
    breadcrumb: {crumbs},
  },
  data,
  location,
}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()

  useEffect(() => {
    if (typeof window !== 'undefined' && data.strapiLawyer.en_disabled) {
      changeLanguage('de')
    }
  }, [])


  const sortedBlogs = data.strapiLawyer.blogs
    .map(i => {
      // eslint-disable-next-line no-param-reassign
      i.sort = new Date(i.date_for_sort).getTime()
      return i
    })
    .sort((a, b) => b.sort - a.sort)
  return (
    <Layout dataLocales={data} location={location}>
      <Header dataLang={data.strapiLawyer.en_disabled} />
      <GeneralContactsHide whiteColor />
      <SEO
        title={data.strapiLawyer.seo_title && data.strapiLawyer.seo_title[language]}
        description={
          data.strapiLawyer.seo_description && data.strapiLawyer.seo_description[language]
        }
      />
      <HeroWithSlider
        dataSingle={data.strapiLawyer}
        // title={data.strapiLawyer.name}
        title_first={
          data.strapiLawyer.h1_title_first ? data.strapiLawyer.h1_title_first[language] : ''
        }
        title_second={
          data.strapiLawyer.h1_title_second ? data.strapiLawyer.h1_title_second[language] : ''
        }
        crumbs={crumbs}
        crumbLabel={data.strapiLawyer.name}
        lawyer
      />
      <BlogNav navData={data.strapiLawyer.navigation[language]} />
      <AboutLawyer content={data.strapiLawyer.about[language]} id="aboutLawyer" />
      {data.strapiLawyer.name === 'Dirk Stresska' ? (
        <LawyerFeature
          title={t('sections.lawyer.lawyerFeatureTitleDirk')}
          subtitle={t('sections.lawyer.lawyerFeatureSubtitle')}
          services={data.strapiLawyer.services}
          servicesText={data.strapiLawyer.service_text[language]}
          id="lawyerFeature"
        />
      ) : (
        <LawyerFeature
          title={t('sections.lawyer.lawyerFeatureTitle')}
          subtitle={t('sections.lawyer.lawyerFeatureSubtitle')}
          services={data.strapiLawyer.services}
          servicesText={data.strapiLawyer.service_text[language]}
          id="lawyerFeature"
        />
      )}

      <LawyerContacts
        name={data.strapiLawyer.name}
        position={data.strapiLawyer.position[language]}
        street={data.strapiLawyer.street}
        contactInfo={data.strapiLawyer.contact_info[language]}
        speak={data.strapiLawyer.speak[language]}
        id="lawyerContacts"
      />
      <ReviewsSection />
      <BlogBlockSection
        dataSingle={sortedBlogs}
        title={`${t('sections.blog.titleSingleLawyer')} ${data.strapiLawyer.name}`}
        subtitle={`${t('sections.blog.subtitleSingleLawyer')} ${data.strapiLawyer.name}`}
        location={location}
      />
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query LawyerTemplate($page_url: String!, $language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    strapiLawyer(page_url: {eq: $page_url}) {
      en_disabled
      seo_title {
        de
        en
      }
      seo_description {
        de
        en
      }
      title {
        de
        en
      }
      h1_title_first {
        de
        en
      }
      h1_title_second {
        de
        en
      }
      services {
        name {
          de
          en
        }
      }
      speak {
        de
        en
      }
      street
      contact_info {
        de
        en
      }
      photo {
        url
      }
      service_text {
        de
        en
      }
      about {
        de
        en
      }
      name
      page_url
      position {
        de
        en
      }
      navigation {
        en
        de
      }
      blogs {
        date_for_sort
        page_url
        title {
          de
          en
        }
        description_short {
          de
          en
        }
        date {
          de
          en
        }
      }
    }
  }
`
